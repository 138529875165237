.side_panel > div:nth-child(3) {
    background: rgba(24, 27, 31, 0.9);
    box-sizing: border-box;
    backdrop-filter: blur(30px);
    color: #F2F2F2;
    width: 460px;
}

.side_panel .side_panel_content {
    padding: 20px;
    margin: unset;
    display: flex;
    flex-direction: column;
    justify-content: unset;
}

.side_panel .section3 .chain_id {
    padding: 20px;
}

.side_panel .no_data {
    background: rgba(35, 40, 44, 0.4);
    border: 1px solid #22272B;
    border-radius: 5px;
    flex: unset;
}

.side_panel .claim_button {
    background: linear-gradient(91.79deg, #fac01e 3.15%, #fc762a 85.66%);
    border-radius: 5px;
    font-family: 'Quantico', sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #000;
    padding: 10px 15px;
    text-transform: capitalize;
    margin-bottom: 20px;
    width: 100%;
}

.side_panel .transaction_history h4,
.side_panel .transaction_queue h4 {
    margin: 0 0 15px;
    text-align: left;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    font-feature-settings: 'zero' on;
    color: #FFFFFF;
}

.transaction_history .transaction_card,
.transaction_queue .queue_card {
    border: 1px solid #292929;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 12px;
    margin-bottom: 10px;
}

.transaction_card .snackbar_class,
.queue_card .snackbar_class {
    display: flex;
    align-items: center;
}

.queue_card .snackbar_class > span {
    text-align: left;
}

.transaction_card .snackbar_class img,
.queue_card .snackbar_class img {
    margin-right: 10px;
}

.transaction_card .snackbar_class span {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    font-feature-settings: 'zero' on;
    color: #FFFFFF;
    text-align: left;
}

.transaction_card .tx_hash {
    display: flex;
    align-items: center;
    margin-left: 40px;
}

.transaction_card .tx_hash .nft_count {
    display: flex;
    flex-shrink: 0;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    font-feature-settings: 'zero' on;
    margin-right: 10px;
}

.transaction_card_success .tx_hash .nft_count {
    color: #00FFB3;
}

.transaction_card_failed .tx_hash .nft_count {
    color: #EB5757;
}

.transaction_card_in_progress .tx_hash .nft_count {
    background: linear-gradient(91.79deg, #fac01e 3.15%, #fc762a 85.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.transaction_card .tx_hash > span {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    font-feature-settings: 'zero' on;
    color: #828282;
    margin-right: 4px;
}

.transaction_card .tx_hash > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
}

.transaction_card .action_buttons {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.transaction_card .action_buttons img[alt='share'] {
    width: 14px;
    cursor: pointer;
}

/*transaction queue*/
.side_panel .transaction_queue {
    margin-bottom: 20px;
}

.queue_card .count_info {
    display: flex;
    align-items: center;
    margin: 10px 0 0 30px;
}

.queue_card .count_info p {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    font-feature-settings: 'zero' on;
    color: #FFFFFF;
    text-align: left;
}

.queue_card .count_info p > span {
    background: linear-gradient(90deg, #FAC01E 0%, #FC762A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.queue_card .count_info p.nft_count {
    margin-left: 20px;
}

.side_panel .side_panel_content .back_button {
    display: none;
}

.side_panel .back_button > button {
    background: rgba(75, 75, 75, .5);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 50px;
    padding: 10px;
}

.side_panel .back_button > button svg {
    width: 12px;
}

.side_panel .section3 > div {
    width: 100%;
}

.side_panel .circle_image {
    width: 30px;
    height: 30px;
    background: #8E96AB;
    border-radius: 50px;
    margin-right: 10px;
}

.side_panel .copy_button.copy_icon_button {
    box-shadow: unset;
}

.side_panel .transaction_history {
    margin-bottom: 10px;
}

.side_panel .transaction_history .scroll_bar {
    max-height: 300px;
    flex: unset;
}

@media (max-width: 460px) {
    .side_panel > div:nth-child(3) {
        width: 100%;
    }

    .side_panel .side_panel_content .back_button {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 100%;
        justify-content: center;
        background: unset;
        margin-bottom: 10px;
    }
}

@media (max-width: 425px) {
    .section3 .chain_id .header > div {
        flex-direction: column;
        flex: 1;
    }
}

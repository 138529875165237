.disclaimer_dialog > div:nth-child(3) > div {
    background: rgba(24, 27, 31, 0.9);
    border-radius: 20px;
}

.disclaimer_dialog h2 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.disclaimer_dialog .dialog_content p {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
    text-align: justify;
}

.disclaimer_dialog .actions_div {
    padding: 30px;
}

.disclaimer_dialog .actions_div > button {
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    border-radius: 5px;
    width: 100%;
    text-transform: unset;
    padding: 15px;
}

.disclaimer_dialog .actions_div > button:disabled {
    opacity: 0.4;
}

.disclaimer_dialog .actions_div > button span {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    background: linear-gradient(180deg, #0E151B 0%, #000000 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.mint_dialog > div:nth-child(3) > div {
    background: rgba(24, 27, 31, 0.9);
    border-radius: 10px;
    padding: 40px;
}

.mint_dialog_content {

}

.mint_dialog_content > h2 {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #E7E7E7;
    margin-bottom: 40px;
}

.list_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.list_content > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.list_content > div > span {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #B7B7B7;
}

.list_content > div > p {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #F2F2F2;
}

.list_content > div .to_address {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #F2F2F2;
    max-width: 150px;
}

.list_content > div .nfts_count {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    font-feature-settings: 'zero' on;
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.list_content > div .total_price {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.list_content > div .total_price img {
    width: 20px;
    height: 20px;
}

.list_content > div .total_price > span {
    font-feature-settings: 'zero' on;
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0 10px;
}

.list_content > div .total_price > p {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #F2F2F2;
}

.mint_dialog_actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.mint_dialog_actions .cancel_button {
    position: relative;
    padding: 12px 50px;
    text-transform: capitalize;
    background: linear-gradient(91.79deg, #fac01e 3.15%, #fc762a 85.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-right: 20px;
}

.mint_dialog_actions .cancel_button:before {
    content: "";
    position: absolute;
    background: linear-gradient(180deg, #0e151b, #000);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 5px;
}

.mint_dialog_actions .cancel_button:after {
    content: "";
    background: linear-gradient(91.79deg, #fac01e 3.15%, #fc762a 85.66%);
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -2;
    border-radius: 5px;
}

.mint_dialog_actions .confirm_button {
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    border-radius: 5px;
    padding: 12px 50px;
    text-transform: capitalize;
}

.mint_dialog_actions .confirm_button.x {
    width: 100%;
    padding: 12px 50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mint_dialog_actions .confirm_button svg {
    fill: #000000;
    margin-right: 10px;
}

.mint_dialog_actions .confirm_button:disabled {
    opacity: 0.4;
}

.mint_dialog_actions .confirm_button > p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    background: linear-gradient(180deg, #0E151B 0%, #000000 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.mint_dialog .hash_text .name {
    margin-right: -2px;
}

.mint_dialog .mint_dialog_success > h2 {
    font-family: 'Quantico', sans-serif;
    font-weight: 400;
    font-size: 30px;
    line-height: 43px;
    color: #FFFFFF;
    margin-bottom: 30px;
}

.mint_dialog .mint_dialog_success > span {
    font-family: 'Quantico', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #FFFFFF;
}

.mint_dialog .mint_dialog_success .hash {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #696969;
    border: 1px solid #696969;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 5px 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.mint_dialog .mint_dialog_success > div > span {
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 90%;
}

.mint_dialog .mint_dialog_success .list_content {
    background: #23282c66;
    border-radius: 20px;
    margin-top: 30px;
    padding: 20px;
}

.mint_dialog .mint_dialog_success .list_content > div:last-child {
    margin: unset;
}

.mint_dialog .mint_dialog_success .denom_symbol {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #B7B7B7;
    margin-bottom: 5px;
}

.mint_dialog .mint_dialog_success .denom_name {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #F2F2F2;
    margin-bottom: 30px;
}

@media (max-width: 425px) {
    .mint_dialog > div:nth-child(3) > div {
        padding: 20px;
        margin: 20px;
    }

    .mint_dialog_actions {
        flex-wrap: wrap;
    }
}

@media (max-width: 320px) {
    .list_content > div {
        flex-direction: column;
        align-items: flex-start;
    }

    .mint_dialog_actions .cancel_button {
        margin: unset;
    }
}

.server_down {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #FFFFFF;
}

.server_down > img {
    width: 80px;
    margin-right: 20px;
}
